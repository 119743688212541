.card-header {
  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    height: 55px;
  }

  .card-header-title {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: auto;
    font-weight: bold;
  }

  .nav-tabs {
    .nav-link {
      font-size: .9rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}

.card-footer-btn {
  display: flex;
  align-items: center;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.card-bottom-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 $card-spacer-x 1.5rem;
}


// 
// Table inside card
// 

.card-table {
  margin-bottom: 0;

  thead th {
    border-top-width: 0;

    &:first-child {
      padding-left: 1.5rem!important;
    }
  }

  tbody td {
    &:first-child {
      padding-left: 1.5rem!important;
    }
  }
}
