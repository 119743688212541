// 
// Overlay utility
// 

.overlay {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  }
}

// Context colors

@each $color, $value in $theme-colors {
  .overlay-#{$color}:before {
    background: $value !important;
  }
}

.overlay-white:before {
  background: $white !important;
}


// Opacities

@each $size, $length in $sizes {
  @if is-integer($size) {
    .overlay-#{$size}:before {
      opacity: $size / 100;
    }
  }
}
