// 
// Navbar
// 

.navbar {
  border-width: 0 0 1px 0;
  border-style: solid;
  z-index: $zindex-navbar;

  @include transition($bg-transition);

  .dropdown-menu {
    @include media-breakpoint-up(lg) {
      margin-top: -1px !important;
      margin-left: 0px !important;
      display: block;
      visibility: hidden;
      opacity: 0;
      @include transition(opacity .25s);

      &.show {
        opacity: 1;
        visibility: visible;
        z-index: $zindex-navbar;
      }

      .dropdown-item {
        font-size: 1.05rem;
      }
    }
  }
}

.navbar-collapse {
  .svg-icon {
    svg:not([fill=none]),
    [fill]:not([fill=none]) {
      fill: currentColor!important;
    }
  }
}


// 
// Skins
// 

.navbar-light {
  border-color: rgba($dark, .09);

  &.bg-transparent.navbar-toggled {
    background-color: $white !important;
  }

  .navbar-collapse:before {
    border-top-color: rgba($dark, .09);
  }

  .navbar-divider {
    border-color: $pistachio;
    width: 100%;
  }
}

.navbar-dark {
  border-color: rgba($dark, .09);

  &.bg-transparent.navbar-toggled {
    background-color: $gray-900 !important;
  }

  .navbar-divider {
    border-color: $black;
    width: 100%;
  }
}
