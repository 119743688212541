// 
// Tables
// 

.table {
  thead {
    th {
      color: $table-th-color;
      font-size: $table-th-font-size;
      text-transform: uppercase;
      letter-spacing: .05rem;
    }
  }

  td, th {
    border-bottom: 0;
    border-top: 1px solid #edf2f9;
  }

  [data-sort]:after {
    content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='%231E2E50'/></svg>");
    margin-left: .25rem;
  }
}

.table thead th, tbody th, tbody td {
  vertical-align: middle;
}

.table-sm {
  font-size: $table-font-size-sm;
}


// 
// No wrap
// Prevents table content from wrapping to the next line
// 

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}
