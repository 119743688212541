.chart {
  position: relative;
  height: 300px;
}

#custom-tooltip {
  z-index: 0;
}

#custom-tooltip .popover-arrow {
  top: 100%;
  left: 50%;
}

.chart-legend {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  font-size: $font-size-sm;
  text-align: center;
  color: $dark;
}

.chart-legend-item {
  display: inline-flex;
  align-items: center;

  + .chart-legend-item {
    margin-left: 1rem;
  }
}

.chart-legend-color {
  display: inline-block;
  width: .5rem;
  height: .5rem;
  margin-right: 0.375rem;
  border-radius: 50%;
}
