// 
// Switch
// 

.form-switch {
  .form-check-input {
    height: 1.5em;
    cursor: pointer;
    margin-top: 0;
  }
}
