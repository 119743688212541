// 
// Relative top helpers
// 

@for $i from 1 through 10 {
  .relative-top-#{$i} {
    position: relative;
    top: #{$i}px;
  }

  .relative-top--#{$i} {
    position: relative;
    top: -#{$i}px;
  }
}
