// Enable background cover 

.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-cover-bottom {
  background-position: center bottom;
}

// Pastel theme colors

@each $color, $value in $theme-colors {
  .bg-pastel-#{$color} {
    background-color: tint-color($value, 90%) !important;
  }
}
